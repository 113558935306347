<template>
    <ul class="menu_parent">
      <li :class="['menu_parent_li',item.active? 'active':'',item.open? 'open':'']" v-for="item in menuData" :key="item.path" :data-child="item.children? '1':'0'">
        <div class="menu_parent_li_block" @click="batchMenu(item)">
          <a-icon :type="iconMap[item.icon]" theme="filled"/>
          <div class="menu_parent_li_text">{{item.title}}</div>
          <a-icon type="caret-down" v-if="item.children&&!item.open"/>
          <a-icon type="caret-up" v-if="item.children&&item.open"/>
        </div>
        <ul class="menu_child" v-if="item.open">
          <li :class="['menu_child_li',child.active? 'active':'']" v-for="child in item.children" :key="child.path" @click.stop="batchMenu(child)">
            {{child.title}}
          </li>
        </ul>
      </li>
    </ul>
</template>

<script>
export default {
  data(){
    this.selectedKeysMap = {};
    const menuData = this.initializeMenuData();
    const iconMap = {'DashBoard':'pie-chart','AssetManager':'appstore','AssetConfig':'setting','DataReport':'fund','SystemManager':'control','ApprovalConfig':'schedule'};
    return{
      menuData,
      iconMap
    }
  },
  mounted(){
    this.processActive(this.$route.path);
  },
  watch: {
    "$route.path": function(val){
      this.processActive(val);
    }
  },
  methods: {
    initializeMenuData(){
      const data = JSON.parse(sessionStorage.getItem("menudata"));
      const temp = [...data];
      temp.forEach(item => {
        this.processTreeData(item,null);
      });
      return temp;
    },
    processTreeData(item,parent){
      if(item.shadow){
        if(parent){
          this.selectedKeysMap[item.path] = parent;
        }
      }else{
        item.active = false;
        this.selectedKeysMap[item.path] = item.path;
        if(item.children && item.children.length){
          item.open = item.dropdown? true:false;
          item.children.forEach(children => {
            this.processTreeData(children,item.path);
          })
        }else{
          item.active = false;
        }
      }
    },
    processActive(path){
      let target = this.selectedKeysMap[path];
      this.menuData.forEach(item => {
        if(item.path==target){
          item.active = true;
        }else{
          item.active = false;
        }
        if(item.children){
          item.children.forEach(children => {
            if(children.path==target){
              children.active = true;
              item.open = true;
            }else{
              children.active = false;
            }
            if(item.children.children){
              item.children.children.forEach(grandson => {
                if(grandson.path==target){
                  children.active = true;
                  item.open = true;
                }
              })
            }
          })
        }
      });
    },
    batchMenu(item){
      if(item.children && item.children.length){
        let hasRealChild = false;
        item.children.forEach(temp => {
          if(!temp.shadow){
            hasRealChild = true;
            return;
          }
        })
        if(hasRealChild){
          item.open = !item.open;
        }else{
          this.$router.push({ path: item.path });
        }
      }else{
        this.$router.push({ path: item.path });
      }
    },
  }
}
</script>

<style lang="less" scoped>
.menu_parent{
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}
.menu_parent_li{
  width: 100%;
  cursor: pointer;
  position: relative;
}
.menu_parent_li.active .menu_parent_li_block{
  background-color: rgba(255,255,255,0.15);
  border-left: 3px solid #cedce5;
}
.menu_parent_li_block{
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255,255,255, 0.75);
  box-sizing: border-box;
  border-left: 3px solid #1b2a4e;
}
.menu_parent_li img{
  width: 20px;
  height: 20px;
  margin-right: 17px;
}
.menu_parent_li_block:hover{
  border-left: 3px solid rgba(255,255,255, 0.1);
  background-color: rgba(255,255,255, 0.1);
}
.menu_parent_li_block:hover{
  color: #FFFFFF;
  font-weight: bold;
}
.menu_parent_li.active .menu_parent_li_block{
  color: #FFFFFF !important;
  font-weight: bold !important;
}
.menu_parent_li.open .menu_parent_li_block{
  color: #FFFFFF !important;
  font-weight: bold !important;
}
li{
  list-style: none;
}
.menu_parent_li_text{
  width: calc(100% - 40px);
  margin-left: 12px;
}
.menu_child{
  width: 100%;
  padding-left: 0px;
  position: relative;
  top: 0;
}
.menu_child_li{
  width: 100%;
  height: 36px;
  padding-left: 58px;
  position: relative;
  line-height: 36px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.75);
  box-sizing: border-box;
  border-left: 3px solid #1b2a4e;
}
.menu_child_li.active{
  border-left: 3px solid #cedce5 !important;
  background-color: rgba(255,255,255, 0.15) !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
}
.menu_child_li:hover{
  border-left: 3px solid rgba(255,255,255, 0.1);
  background-color: rgba(255,255,255, 0.1);
  color: #FFFFFF;
  font-weight: bold;
}
</style>