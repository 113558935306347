<template>
  <div class="backstagelayout_main">
    <div class="backstagelayout_left">
      <div class="backstagelayout_left_top">鲲开码资产管理平台</div>
      <div class="backstagelayout_left_bottom">
        <Menu />
      </div>
    </div>
    <div class="backstagelayout_right">
      <div class="backstagelayout_right_top">
        <a-dropdown placement="bottomRight">
          <div class="backstagelayout_user">
            <a-icon type="user"/>
            <font class="backstagelayout_user_name">{{userName}}</font>
            <a-icon type="caret-down"/>
          </div>
          <a-menu class="backstagelayout_user_info" slot="overlay">
            <a-spin :spinning="userInfoSpinning">
              <a-icon slot="indicator" type="loading"/>
              <div class="fullContainer backstagelayout_user_info_board">
                <div v-if="!userInfoSpinning&&userRole=='role_tenant'" class="backstagelayout_user_info_text">{{userInfo.name}}</div>
                <div v-if="!userInfoSpinning&&userRole=='role_tenant'" class="backstagelayout_user_info_text">{{userInfo.position}}</div>
                <div v-if="!userInfoSpinning&&userRole=='role_admin'" class="backstagelayout_user_info_text">您好，运营管理员</div>
                <div v-if="!userInfoSpinning" class="backstagelayout_user_info_btn_board">
                  <div v-if="userRole=='role_tenant'" @click="callUserPutInfoDialog">修改帐户信息</div>
                  <div @click="callUserPutPhoneNumberDialog">修改手机号码</div>
                  <div @click="callUserPutPasswordDialog">修改登录密码</div>
                  <div @click="exeLogout">退出系统</div>
                </div>
              </div>
            </a-spin>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="backstagelayout_right_bottom">
        <router-view />
      </div>
    </div>
    <UserPutInfoDialog v-if="userRole=='role_tenant'" :dialogConfig="UserPutInfoDialogConfig" @submit="putUserInfo"/>
    <UserPutPhoneNumberDialog :dialogConfig="UserPutPhoneNumberDialogConfig" @submit="putUserPhoneNumber"/>
    <UserPutPasswordDialog  :dialogConfig="UserPutPasswordDialogConfig" @submit="putUserPassword"/>
  </div>
</template>

<script>
import {exeLogout} from "../utils/authorization";
import Menu from "../components/Menu.vue";
import UserPutInfoDialog from "../components/UserPutInfoDialog.vue";
import UserPutPhoneNumberDialog from "../components/UserPutPhoneNumberDialog";
import UserPutPasswordDialog from "../components/UserPutPasswordDialog";
export default {
  components: {
    Menu,
    UserPutInfoDialog,
    UserPutPhoneNumberDialog,
    UserPutPasswordDialog
  },
  mounted(){
    this.getData();
  },
  data(){
    return{
      userName: localStorage.getItem('userName') || "",
      UserPutInfoDialogConfig: { visible:false,requesting:false,userInfo:{} },
      UserPutPhoneNumberDialogConfig: { visible:false,requesting:false,userInfo:{} },
      UserPutPasswordDialogConfig: { visible:false,requesting:false,userInfo:{} },
      userRole: localStorage.getItem('role'),
      userInfoSpinning: false,
      userInfo: { name:'',phoneNumber:'',email:'',position:'' }
    }
  },
  methods: {
    exeLogout(){
      exeLogout(()=>{ this.$router.go(0); });
    },
    callUserPutInfoDialog(){
      this.UserPutInfoDialogConfig = { visible:true,requesting:false,userInfo:this.userInfo };
    },
    callUserPutPhoneNumberDialog(){
      this.UserPutPhoneNumberDialogConfig = { visible:true,requesting:false,userInfo:this.userInfo };
    },
    callUserPutPasswordDialog(){
      this.UserPutPasswordDialogConfig = { visible:true,requesting:false,userInfo:this.userInfo };
    },
    getData(){
      this.userInfoSpinning = true;
      let that = this;
      if(this.userRole=="role_admin"){
        this.$get("/user/self",{})
        .then(function(res){
          that.userInfoSpinning = false;
          that.userInfo.phoneNumber = res.data.userName;
        })
      }else{
        this.$get("/userEmployee/self",{})
        .then(function(res){
          that.userInfoSpinning = false;
          that.userInfo = res.data;
          that.userInfo.email = that.userInfo.email? that.userInfo.email:'';
          that.userInfo.position = that.userInfo.position? that.userInfo.position:'';
        })
      }
    },
    putUserInfo(values){
      this.UserPutInfoDialogConfig.requesting = true;
      let that = this;
      this.$put("/userEmployee/self",{id:this.userInfo.id,...values})
      .then(function(){
        that.UserPutInfoDialogConfig.requesting = false;
        that.$message.success("保存帐户信息成功");
        that.UserPutInfoDialogConfig.visible = false;
        that.getData();
      })
      .catch(function(){
        that.UserPutInfoDialogConfig.requesting = false;
      })
    },
    putUserPhoneNumber(values){
      this.UserPutPhoneNumberDialogConfig.requesting = true;
      let that = this;
      const formData = new FormData();
      formData.append("username", values.username);
      formData.append("oldPhoneCaptcha", values.oldPhoneCaptcha);
      formData.append("newPhoneCaptcha", values.newPhoneCaptcha);
      this.$put("/user/username",formData)
      .then(function(){
        that.UserPutPhoneNumberDialogConfig.requesting = false;
        that.$message.success('更改手机号码成功，请重新登录');
        that.UserPutPhoneNumberDialogConfig.visible = false;
        localStorage.removeItem('RefreshAuthorization');
        localStorage.removeItem('AccessAuthorization');
        localStorage.removeItem('userName');
        that.$router.push({path:'/',query:{}});
      })
      .catch(function(){
        that.UserPutPhoneNumberDialogConfig.requesting = false;
      })
    },
    putUserPassword(values){
      this.UserPutPasswordDialogConfig.requesting = true;
      let that = this;
      const formData = new FormData();
      formData.append("username", this.userInfo.phoneNumber);
      formData.append("password", values.password);
      formData.append("captcha", values.captcha);
      this.$put("/user/password",formData)
      .then(function(){
        that.UserPutPasswordDialogConfig.requesting = false;
        that.$message.success('更改密码成功');
        that.UserPutPasswordDialogConfig.visible = false;
      })
      .catch(function(){
        that.UserPutPasswordDialogConfig.requesting = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.backstagelayout_main{
  width: 100%;
  height: 100%;
  float: left;
  overflow-x: hidden;
  overflow-y: hidden;
}
.backstagelayout_left{
  width: 254px;
  height: 100%;
  float: left;
  background-color: #1b2a4e;
}
.backstagelayout_left_top{
  width: 100%;
  height: 60px;
  float: left;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255,255,255,0.05);
  padding: 0px 30px;
  line-height: 60px;
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
}
.backstagelayout_right{
  width: calc(100% - 254px);
  height: 100%;
  float: left;
}
.backstagelayout_right_top{
  width: 100%;
  height: 60px;
  float: left;
  background-color: #FFFFFF;
  box-shadow: 0 0 35px 0 rgba(154,161,171,0.18);
  -webkit-box-shadow: 0 0 35px 0 rgba(154,161,171,0.18);
  padding: 0 30px;
}
.backstagelayout_user{
  width: auto;
  height: 36px;
  border-radius: 20px;
  line-height: 34px;
  color: #1b2a4e;
  padding: 0 15px;
  cursor: pointer;
  margin: 12px 0px;
  float: right;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px #FFFFFF solid;
}
.backstagelayout_user_name{
  margin: 0px 6px;
}
.backstagelayout_user:hover{
  background-color: rgba(27,42,78,0.05);
}
.backstagelayout_left_bottom{
  width: 100%;
  height: calc(100% - 60px);
  float: left;
  padding: 10px 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.backstagelayout_user_info{
  width: 180px;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 5px;
  float: left;
  padding: 0;
  overflow: hidden;
}
.backstagelayout_user_info_board{
  background-color: #1b2a4e;
  padding: 10px 0 0 0;
}
.backstagelayout_user_info_text{
  width: 100%;
  line-height: 25px;
  font-size: 14px;
  padding: 0 15px;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.backstagelayout_user_info_btn_board{
  width: 100%;
  height: auto;
  margin-top: 10px;
  float: left;
  background-color: #FFFFFF;
}
.backstagelayout_user_info_btn_board div{
  width: 100%;
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  color: #1b2a4e;
  float: left;
  cursor: pointer;
}
.backstagelayout_user_info_btn_board div:hover{
  background-color: rgba(27,42,78,0.05);
}
.backstagelayout_right_bottom{
  width: 100%;
  height: calc(100% - 60px);
  float: left;
  z-index: -1;
}
/deep/ .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
  text-align: center;
}
/deep/ .ant-spin-nested-loading{
  height: 100%;
}
/deep/ .ant-spin-container{
  height: 100%;
}
</style>