<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="修改手机号码" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="480" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="userPhoneNumberdialog_block">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="原手机号码">
              {{dialogConfig.userInfo.phoneNumber}}
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="原手机验证码">
              <a-input-search placeholder="请输入原手机验证码" @search="getCodeForOldPhone" v-decorator="['oldPhoneCaptcha',{rules:[{required:true,message:'请输入原手机验证码'}]}]">
                <a-button slot="enterButton" :disabled="oldPhoneCodeBtnText!='获取验证码'">{{oldPhoneCodeBtnText}}</a-button>
              </a-input-search>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="新手机号码">
              <a-input placeholder="请输入新手机号码" v-decorator="['username',{rules:[{required:true,message:'请输入新手机号码'},{pattern:/^[1][0-9][0-9]{9}$/,message:'请输入正确的手机号码'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="新手机验证码">
              <a-input-search placeholder="请输入新手机验证码" @search="getCodeForNewPhone" v-decorator="['newPhoneCaptcha',{rules:[{required:true,message:'请输入新手机验证码'}]}]">
                <a-button slot="enterButton" :disabled="newPhoneCodeBtnText!='获取验证码'">{{newPhoneCodeBtnText}}</a-button>
              </a-input-search>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 8 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 13 } },
      },
      oldPhoneCodeBtnText: "获取验证码",
      oldPhoneCapchaTimes: 60,
      newPhoneCodeBtnText: "获取验证码",
      newPhoneCapchaTimes: 60
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        this.$emit('submit',values);
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    getCodeForOldPhone(values,event){
      if(event.type=="keydown"){ return; }
      this.oldPhoneCodeBtnText = "获取中";
      let that = this;
      const formData = new FormData();
      formData.append("phoneNumber", this.dialogConfig.userInfo.phoneNumber);
      this.$post("/code/oldPhone",formData)
      .then(function(){
        that.timerA = setInterval(()=>{
          that.oldPhoneCodeBtnText = that.oldPhoneCapchaTimes+"s";
          that.oldPhoneCapchaTimes--;
          if(that.oldPhoneCapchaTimes<0){
            clearInterval(that.timerA);
            that.oldPhoneCodeBtnText = "获取验证码";
            that.oldPhoneCapchaTimes = 60;
          }
        },1000)
      })
      .catch(function(){
        that.oldPhoneCodeBtnText = "获取验证码";
        that.oldPhoneCapchaTimes = 60;
      })
    },
    getCodeForNewPhone(values,event){
      if(event.type=="keydown"){ return; }
      this.form.validateFields(['username'],(errors, values) => {
        if(errors){ return; }
        this.newPhoneCodeBtnText = "获取中";
        let that = this;
        const formData = new FormData();
        formData.append("phoneNumber", values.username);
        this.$post("/code/newPhone",formData)
        .then(function(){
          that.timerB = setInterval(()=>{
            that.newPhoneCodeBtnText = that.newPhoneCapchaTimes+"s";
            that.newPhoneCapchaTimes--;
            if(that.newPhoneCapchaTimes<0){
              clearInterval(that.timerB);
              that.newPhoneCodeBtnText = "获取验证码";
              that.newPhoneCapchaTimes = 60;
            }
          },1000)
        })
        .catch(function(){
          that.newPhoneCodeBtnText = "获取验证码";
          that.newPhoneCapchaTimes = 60;
        })
      });
    }
  }
}
</script>

<style lang="less" scoped>
.userPhoneNumberdialog_block{
  width: 100%;
  height: auto;
  padding: 30px 0 5px 0;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
</style>