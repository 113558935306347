<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="修改帐户信息" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="450" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="userinfodialog_block">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="姓名">
              <a-input placeholder="请输入姓名" v-decorator="['name',{initialValue:dialogConfig.userInfo.name,rules:[{required:true,message:'请输入姓名'},{ max: 10, message: '最多填写10个字符' }]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="邮箱">
              <a-input placeholder="请输入邮箱" v-decorator="['email',{initialValue:dialogConfig.userInfo.email,rules:[{pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message:'邮箱格式不正确'},{ max: 50, message: '最多填写50个字符' }]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="职位">
              <a-input placeholder="请输入职位" v-decorator="['position',{initialValue:dialogConfig.userInfo.position,rules:[{ max: 20, message: '最多填写20个字符' }]}]"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 5 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 16 } },
      }
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        this.$emit('submit',values);
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.userinfodialog_block{
  width: 100%;
  height: auto;
  padding: 30px 0 5px 0;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
</style>