<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="修改登录密码" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="450" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="userinfodialog_block">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="手机号码">
              {{dialogConfig.userInfo.phoneNumber}}
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="验证码">
              <a-input-search placeholder="请输入3验证码" @search="getCode" v-decorator="['captcha',{rules:[{required:true,message:'请输入验证码'}]}]">
                <a-button slot="enterButton" :disabled="phoneCodeBtnText!='获取验证码'">{{phoneCodeBtnText}}</a-button>
              </a-input-search>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="新密码">
              <a-input type="password" placeholder="请输入新密码" v-decorator="['password',{rules:[{required:true,message:'请输入新密码'},{validator:checkPassword}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemSingleLayout" label="确认密码">
              <a-input type="password" placeholder="请输入新密码" v-decorator="['passwordConfirm',{rules:[{required:true,message:'请输入新密码'},{validator:checkPassword}]}]"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 7 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 13 } },
      },
      phoneCodeBtnText: "获取验证码",
      capchaTimes: 60
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        this.$emit('submit',values);
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    getCode(values,event){
      if(event.type=="keydown"){ return; }
      this.phoneCodeBtnText = "获取中";
      let that = this;
      const formData = new FormData();
      formData.append("phoneNumber", this.dialogConfig.userInfo.phoneNumber);
      this.$post("/code/changePassword",formData)
      .then(function(){
        that.timerA = setInterval(()=>{
          that.phoneCodeBtnText = that.capchaTimes+"s";
          that.capchaTimes--;
          if(that.capchaTimes<0){
            clearInterval(that.timerA);
            that.phoneCodeBtnText = "获取验证码";
            that.capchaTimes = 60;
          }
        },1000)
      })
      .catch(function(){
        that.phoneCodeBtnText = "获取验证码";
        that.capchaTimes = 60;
      })
    },
    checkPassword(rule, value, callback){
      var p1 = this.form.getFieldValue("password");
      var p2 = this.form.getFieldValue("passwordConfirm");
      if(p1==""||p2==""||p1==null||p2==null){
        callback()
      }else if(p1!=p2){
        callback("两次输入的密码不一致")
        this.form.validateFields(['password','passwordConfirm']);
      }else{
        callback();
        this.form.validateFields(['password','passwordConfirm']);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.userinfodialog_block{
  width: 100%;
  height: auto;
  padding: 30px 0 5px 0;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
</style>